import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import GridContent from '../components/UI/grid-content'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Carousel from '../components/UI/carousel/carousel'

const Team = ({ data, location }) => {
  const { team } = data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <Layout
      location={typeof window !== 'undefined' && location}
      crumbLabel="Team"
    >
      <GatsbySeo
        title="Team"
        description="Our team comprises experienced professionals and industry
          experts who are passionate about driving innovation and sustainable
          growth in the green energy sector."
      />
      <GridContent id="team" layout="--center-6-offset" mode="dark-mode">
        <h4>{team.section}</h4>
        <h1>Driving Innovation and Sustainable Growth</h1>
        <h2>
          At Irbisio, our team comprises experienced professionals and industry
          experts who are passionate about driving innovation and sustainable
          growth in the green energy sector. Together, we bring a diverse range
          of expertise and a shared commitment to making a positive impact.
          Allow us to introduce our key team members:
        </h2>
        {/* <Carousel>
          {team.members.map((member, i) => {
            const image = getImage(member.photo)
            return (
              <li
                className="slide"
                id={`carousel__slide-${i}`}
                tabIndex="0"
                key={i}
              >
                <div className="team-sheet">
                  <div className="team-details">
                    <div style={{ width: '100%' }}>
                      <h1>{member.name}</h1>
                      <h4>{member.title}</h4>
                    </div>
                    <GatsbyImage
                      image={image}
                      alt={member.name}
                      style={{
                        marginBottom: '-2px',
                        maxHeight: '240px',
                      }}
                      imgStyle={{
                        objectPosition: 'right bottom',
                        objectFit: 'scale-down',
                      }}
                    />
                  </div>
                  <p className="markdown">{member.body}</p>
                </div>
              </li>
            )
          })}
        </Carousel> */}
        <h2>
          Our team's collective expertise, experience, and commitment to
          sustainability enable us to identify and nurture transformative
          projects in the green energy sector. Together, we strive to drive
          innovation, deliver sustainable returns, and make a lasting positive
          impact on the environment and society.
        </h2>
      </GridContent>
    </Layout>
  )
}

export default Team

export const query = graphql`
  query {
    allFile(filter: { name: { eq: "about" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              team {
                members {
                  body
                  name
                  photo {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: NONE
                      )
                    }
                  }
                  title
                }
                image {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                mode
                section
              }
            }
          }
        }
      }
    }
  }
`
